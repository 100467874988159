var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"row",staticClass:"row page-break"},[_c('div',{staticClass:"col level-col",style:({
      'background-color': _vm.cognitiveLeveLColor + ' !important',
      border: 'solid ' + _vm.cognitiveLeveLColor + ' !important',
      color: _vm.$lightOrDark(_vm.cognitiveLeveLColor)
        ? '#2b2a2a !important'
        : 'white !important',
    })},[_c('span',[_vm._v("Nivel "+_vm._s(_vm.cognitive_level.level))]),_c('br'),(_vm.allows_crud)?_c('ResizeAuto',{scopedSlots:_vm._u([{key:"default",fn:function({ resize }){return [_c('b-form-textarea',{staticClass:"input-level",style:({
            'background-color': _vm.cognitiveLeveLColor + ' !important',
            color: _vm.$lightOrDark(_vm.cognitiveLeveLColor)
              ? '#2b2a2a !important'
              : 'white !important',
            border: 'none',
          }),attrs:{"placeholder":"Inserte el nombre aquí..."},on:{"input":resize},model:{value:(_vm.cognitive_level.name),callback:function ($$v) {_vm.$set(_vm.cognitive_level, "name", $$v)},expression:"cognitive_level.name"}})]}}],null,false,1186867108)}):_c('p',[_vm._v(_vm._s(_vm.cognitive_level.name))])],1),_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onSecondColResize),expression:"onSecondColResize"}],ref:"second_col",staticClass:"col verbs-col",style:({ minHeight: _vm.row_height + 'px' })},[_c('ResizeAuto',{scopedSlots:_vm._u([{key:"default",fn:function({ resize }){return [_c('b-form-textarea',{staticClass:"textarea",staticStyle:{"color":"white !important"},attrs:{"placeholder":"Inserte la descripción aquí...","disabled":!_vm.allows_crud},on:{"input":resize},model:{value:(_vm.cognitive_level.description),callback:function ($$v) {_vm.$set(_vm.cognitive_level, "description", $$v)},expression:"cognitive_level.description"}})]}}])})],1),_c('div',{ref:"third_col",staticClass:"col verbs-col",staticStyle:{"padding-left":"5px","padding-right":"15px"},style:({ minHeight: _vm.row_height + 'px' })},[_c('tag-list',{attrs:{"color":_vm.cognitiveLeveLColor,"words":_vm.cognitive_level.verbs,"cognitive_level_id":_vm.cognitive_level.id,"allows_crud":_vm.allows_crud}})],1),(_vm.allows_crud)?_c('div',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_cognitivelevel mesh.delete_cognitivelevel'),expression:"'mesh.change_cognitivelevel mesh.delete_cognitivelevel'"}]},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('b-button',{attrs:{"size":"sm","variant":"none","title":`Guardar ${_vm.$getVisibleNames(
            'mesh.cognitivelevel',
            false,
            'Nivel Cognitivo'
          )}`},on:{"click":_vm.saveCognitiveLevel}},[_c('b-icon-box-arrow-in-down',{staticClass:"h4 mb-0"})],1)],1),_c('div',{staticClass:"row"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Cambiar Color`),expression:"`Cambiar Color`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-clear-inputs ml-2",on:{"click":function($event){return _vm.$bvModal.show(
              `modal-edit-color-cognitive-level-${_vm.cognitive_level.id}`
            )}}},[_c('b-icon',{attrs:{"font-scale":"1.2","icon":"brush"}})],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('button-edit',{attrs:{"size":"sm","variant":"none"},on:{"click":function($event){return _vm.$bvModal.show(`modal-edit-cognitive-level-${_vm.cognitive_level.id}`)}}})],1),_c('div',{staticClass:"row"},[_c('b-button',{attrs:{"size":"sm","variant":"none","title":`Eliminar ${_vm.$getVisibleNames(
            'mesh.cognitivelevel',
            false,
            'Nivel Cognitivo'
          )}`},on:{"click":_vm.askForDelete}},[_c('b-icon-trash',{staticClass:"h4 mb-0"})],1)],1),_c('div',{staticClass:"row",staticStyle:{"cursor":"move","text-align":"center","margin-left":"-3px"},attrs:{"title":`Arrastrar para ordenar el ${_vm.$getVisibleNames(
          'mesh.cognitivelevel',
          false,
          'Nivel Cognitivo'
        )}.`}},[_c('b-icon-arrows-expand')],1)])]):_vm._e(),_c('b-modal',{attrs:{"id":`modal-edit-cognitive-level-${_vm.cognitive_level.id}`,"hide-footer":true,"title":"Editar Nivel Cognitivo"}},[_c('CognitiveLevelForm',{attrs:{"CognitiveLevel":_vm.cognitive_level,"taxonomy_id":_vm.taxonomy_id,"order":_vm.order},on:{"updated":_vm.slotUpdateCognitiveLevel}})],1),_c('b-modal',{attrs:{"id":`modal-edit-color-cognitive-level-${_vm.cognitive_level.id}`,"centered":"","hide-backdrop":"","hide-footer":true,"title":`Cambiar color al ${_vm.$getVisibleNames(
      'mesh.cognitivelevel',
      false,
      'Nivel Cognitivo'
    )}`},on:{"hide":function($event){return _vm.patchCognitiveLevel({ color: _vm.cognitive_level.color })}}},[_c('CognitiveLevelForm',{attrs:{"CognitiveLevel":_vm.cognitive_level,"taxonomy_id":_vm.taxonomy_id,"selected_color":true,"order":_vm.order},on:{"updated":_vm.slotUpdateCognitiveLevel}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }