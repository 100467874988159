<template>
  <div>
    <vue-tags-input
      v-model="tag"
      :tags="tags"
      @tags-changed="(newTags) => (tags = newTags)"
      @before-adding-tag="checkTag"
      @before-deleting-tag="deleteTag"
      :placeholder="
        allows_crud
          ? `Adicionar ${$getVisibleNames('mesh.verb', false, 'Verbo')}`
          : ''
      "
      :disabled="!allows_crud"
    />
    <!-- <b-form-tags v-model="tags" name="text" class="mb-2"></b-form-tags> -->
  </div>
</template>

<script>
import { VueTagsInput } from "@johmun/vue-tags-input";
import { toast } from "@/utils/utils";

export default {
  name: "TagList",
  components: {
    VueTagsInput,
  },
  props: {
    words: {
      type: Array,
      default: function () {
        return [];
      },
    },
    cognitive_level_id: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "#99C9D8",
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tag: "",
      tags: [],
      verbs: this.words,
    };
  },
  methods: {
    checkTag(obj) {
      let valid = true;
      obj.addTag();
      obj.tag.tiClasses.forEach((element) => {
        if (element == "ti-invalid") {
          valid = false;
          return;
        }
      });
      if (valid) {
        this.createVerb(obj);
      } else {
        toast(
          "Los " +
            this.$getVisibleNames("mesh.verb", true, "Verbos") +
            " no se deben repetir."
        );
      }
    },
    deleteTag(obj) {
      if (!this.allows_crud) return;
      this.deleteVerb(obj);
      this.tags = this.tags.filter((t) => t.text != obj.tag.text);
    },
    createVerb(obj) {
      let verb = {
        name: obj.tag.text,
        cognitive_level: this.cognitive_level_id,
      };
      this.$restful.Post("/mesh/verb/", verb).then((response) => {
        this.verbs.push(response);
        this.$store.commit("MUTATE_CHANGE_VERBS_BY_TAXONOMY", {
          cognitive_level_id: this.cognitive_level_id,
          verbs: this.verbs,
        });
        toast(this.$getVisibleNames("mesh.verb", false, "Verbo") + " añadido.");
      });
    },
    deleteVerb(obj) {
      const index = this.verbs.findIndex((x) => x.name == obj.tag.text);
      if (index != -1) {
        const id = this.verbs[index].id;
        this.$restful.Delete(`/mesh/verb/${id}/`).then(() => {
          this.verbs.splice(index, 1);
          this.$store.commit("MUTATE_CHANGE_VERBS_BY_TAXONOMY", {
            cognitive_level_id: this.cognitive_level_id,
            verbs: this.verbs,
          });
          toast(
            this.$getVisibleNames("mesh.verb", false, "Verbo") + " eliminado."
          );
        });
      }
    },
  },
  mounted() {
    this.verbs.forEach((element) => {
      this.tags.push({
        text: element.name,
        tiClasses: [],
        id: element.id,
        cognitive_level: this.cognitive_level_id,
        style: `color: ${
          this.$lightOrDark(this.color) ? "#2b2a2a" : "white"
        }; background-color: ${this.color}; border: 1px solid ${this.color};`,
      });
    });
  },
  watch: {
    color() {
      const style_tagz = `color: ${
        this.$lightOrDark(this.color) ? "#2b2a2a" : "white"
      }; background-color: ${this.color}; border: 1px solid ${this.color};`;
      this.tags = this.tags.map((item) => {
        return {
          ...item,
          style: style_tagz,
        };
      });
    },
  },
};
</script>

<style>
/* http://www.vue-tags-input.com/#/examples/styling */
/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: var(--primary-color);
  color: black;
}
.vue-tags-input .ti-input {
  padding: 1px 1px;
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}
.ti-new-tag-input {
  background: var(--secondary-color);
}
/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: white;
}
.vue-tags-input ::-moz-placeholder {
  color: white;
}
.vue-tags-input :-ms-input-placeholder {
  color: white;
}
.vue-tags-input :-moz-placeholder {
  color: white;
}
</style>